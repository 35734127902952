import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily:'Georgia,Times New Roman,Times,serif'
  },
  palette: {
    primary: {
      light: '#865067',
      main: '#682542',
      dark: '#48192e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffb733',
      main: '#ffa500',
      dark: '#b27300',
      contrastText: '#008000',
    },
  },
});

export default theme;
