declare var document: any;

function handleScroll() {
  if (document.body.scrollTop > 110 || document.documentElement.scrollTop > 110) {
      document.getElementById('topimg').style.height = '0';
      document.getElementById('trees').style.marginTop = '64px';
  } else if (!document.body.scrollTop && !document.documentElement.scrollTop) {
      document.getElementById('topimg').style.height = '110px';
      document.getElementById('trees').style.marginTop = '174px';
  }
}

export const onClientEntry = () => {
  // Run the handleScroll function every time you scroll
  window.addEventListener('scroll', handleScroll, { passive: true });
}
